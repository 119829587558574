<template>
  <div v-if="item" :class="{ 'border-b border-gray-100 px-1 py-1': row }">
    <div
      class="rounded-lg group p-3 gap-2 flex flex-col group cursor-pointer"
      :class="{ 'text-primary': expanded }"
      @click="expandItem"
    >
      <div class="flex w-full gap-3 items-center">
        <div class="w-5 h-5">
          <fw-icon-loading v-if="loading" class="w-5 h-5" />
          <fw-icon-mail-open v-else-if="expanded" class="w-5 h-5" />
          <fw-icon-mail v-else class="w-5 h-5 text-gray-300" />
        </div>
        <div class="flex-1">
          <v-clamp autoresize :max-lines="1">{{ item.title || $t('noSubject') }}</v-clamp>
        </div>
        <div v-if="user" class="hidden w-1/4 md:flex gap-2 items-center">
          <fw-avatar :user="user" :size="'xs'" />
          <v-clamp class="font-medium text-sm" autoresize :max-lines="1">{{
            user.display_name || user.full_name
          }}</v-clamp>
        </div>
        <div v-else-if="item.sent_to" class="hidden w-1/4 md:flex gap-2 items-center">
          <v-clamp class="font-semibold text-sm" autoresize :max-lines="1">{{ item.sent_to }}</v-clamp>
        </div>

        <div class="text-gray-500 text-sm text-center flex-shrink-0">
          {{ item.created_date | formatDateTime }}
        </div>

        <div v-if="expandable">
          <fw-icon-chevron-up v-if="expanded" class="w-5 h-5" />
          <fw-icon-chevron-down v-else class="w-5 h-5" />
        </div>
      </div>
    </div>
    <!-- v-if="expandable && expanded" -->
    <!-- <div v-if="expandable && expanded" class=""> -->
    <div
      v-if="details != null && expanded"
      class="px-11 py-5 flex text-sm font-medium flex-col items-start md:flex-row gap-2 md:gap-4"
    >
      <div class="flex flex-col gap-1">
        <div v-if="details.message" class="flex flex-col">
          <div class="flex gap-1">
            <fw-label marginless>{{ $t('application') }}:</fw-label>
            <div>{{ item.context.application }}</div>
          </div>
          <div class="flex gap-1">
            <fw-label marginless>{{ $t('notificationKey') }}:</fw-label>
            <div>{{ item.key }}</div>
          </div>
          <div class="flex gap-1">
            <fw-label marginless>{{ $t('notificationType') }}:</fw-label>
            <div class="capitalize">{{ item.type === 'normal' ? 'Email / Web' : item.type }}</div>
          </div>
          <div v-if="item.sent_to" class="flex gap-1">
            <fw-label marginless>{{ $t('sentTo') }}:</fw-label>
            <div v-if="user" class="flex gap-2 items-center">
              <fw-avatar :user="user" :size="'xs'" />
              <v-clamp class="font-semibold text-sm" autoresize :max-lines="1">{{
                user.display_name || user.full_name
              }}</v-clamp>
            </div>
            <div v-else>{{ item.sent_to }}</div>
          </div>
          <div class="mt-2 text-dark">
            <fw-label>{{ $t('message') }}:</fw-label>
            <div
              class="font-medium max-h-96 overflow-auto bg-gray-100 rounded-xl px-5 py-4 w-full"
              v-html="details.message"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!--  </div> -->
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    row: {
      type: Boolean,
      default: true,
    },
    users: {
      type: Object,
      default: () => {},
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    apiEndpoint: {
      type: String,
      default: 'getNotification',
    },
    apiService: {
      type: Object,
      default: null,
    },
    extraPayload: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      //expanded: false,
      //details: null,
      loading: false,
    }
  },

  computed: {
    expanded() {
      return this.item.expanded
    },
    details() {
      return this.item.details
    },
    user() {
      if (this.users && this.item.sent_to in this.users) {
        return this.users[this.item.sent_to]
      }
      return null
    },
    api() {
      return this.$store.state.api.base
    },
  },

  methods: {
    async getNotificationApi() {
      const api = this.apiService != null ? this.apiService : this.api
      if (this.extraPayload) return await api[this.apiEndpoint](this.item.key, this.extraPayload)

      return await api[this.apiEndpoint](this.item.key)
    },

    expandItem() {
      this.loading = true
      if (this.details == null && !this.expanded) {
        this.getNotification()
      } else {
        this.toogleExpand()
      }
    },

    async getNotification() {
      try {
        this.item.details = await this.getNotificationApi()
        this.toogleExpand()
      } catch (error) {
        console.error(error)
      }
    },
    toogleExpand() {
      setTimeout(() => {
        //this.expanded = !this.expanded
        this.item.expanded = !this.expanded
        this.loading = false
      }, 500)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "noSubject": "No subject defined",
    "message": "Message",
    "application": "Application",
    "notificationKey": "Notification key",
    "notificationType": "Notification type",
    "sentTo": "Sent to"
  },
  "pt": {
    "noSubject": "Sem assunto definido",
    "message": "Mensagem",
    "application": "Aplicação",
    "notificationKey": "Chave da notificação",
    "notificationType": "Tipo de notificação",
    "sentTo": "Enviada para"
  }
}
</i18n>
